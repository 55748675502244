import * as React from 'react'
import { Helmet } from 'react-helmet'
import DoYouHaveAnIdea from '../components/DoYouHaveAnIdea'
import Footer from '../components/Footer'
import Header from '../components/Header/Header'
import ServicesContent from '../components/ServicesContent'
import ServicesHero from '../components/ServicesHero'
import '../css/index.css'
import { Spacer } from '../theme/base'

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services</title>
        <meta
          name="description"
          content="We design and build world-class digital products such as mobile/web applications, ai chatbots and more."
        />
        <html lang="en" />
      </Helmet>
      <main style={{ position: 'relative' }}>
        <Header />
        <ServicesHero />
        <ServicesContent />
        <Spacer size="large" />
        <DoYouHaveAnIdea />
        <Spacer size="large" />
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
