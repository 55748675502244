import styled from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'
import Image from '../Image'

export const Container = styled.div``

export const AspectRatio = styled.div`
  width: 100%;
  height: 0;
  padding-top: 120%;
  background-size: cover;
  background-position: center;
`

export const StyledImage = styled(Image).attrs(() => ({ rounded: true }))`
  width: 100%;
  height: 700px;
  object-fit: cover;
  margin-left: auto;

  ${breakpoints.md} {
    height: 500px;
  }
`
