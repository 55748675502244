import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import Reveal, { Fade } from 'react-awesome-reveal'
import { Col, Container, Row, Visible } from 'react-grid-system'
import animations from '../../theme/animations'
import { Spacer } from '../../theme/base'
import { Body1, Headline1 } from '../../theme/typography'
import { navigateToWorkWithCategoryFilters, parseContentfulFileUrl } from '../../util/helpers'
import Button from '../Button'
import Pill from '../__general/Pill'
import * as S from './styled'

type Props = {}

const query = graphql`
  query {
    contentfulServicesPage {
      designAndBranding {
        fixed(height: 2000) {
          srcWebp
        }
        previewFixed: fixed(height: 200) {
          srcWebp
        }
      }
      productDesign {
        fixed(height: 2000) {
          srcWebp
        }
        previewFixed: fixed(height: 200) {
          srcWebp
        }
      }
      aiServices {
        fixed(height: 2000) {
          srcWebp
        }
        previewFixed: fixed(height: 200) {
          srcWebp
        }
      }
      softwareDevelopment {
        fixed(height: 2000) {
          srcWebp
        }
        previewFixed: fixed(height: 200) {
          srcWebp
        }
      }
    }
  }
`

const ServicesContent: FC<Props> = () => {
  const { contentfulServicesPage } = useStaticQuery(query)

  return (
    <Container>
      <Row>
        <Col lg={6} md={12}>
          <Fade direction="left" triggerOnce>
            <span>
              <Headline1>What we do</Headline1>
              <Spacer h08 />
              <Body1>
                We design and build world-class digital products for startups and enterprise.
              </Body1>
            </span>
          </Fade>
        </Col>
      </Row>
      <Spacer size="large" />
      <Row style={{ alignItems: 'center' }} gutterWidth={140}>
        <Col lg={6} md={8}>
          <Reveal triggerOnce keyframes={animations.zoomFade}>
            {/* <div className="animated-gradient-border" id="animated-gradient-box">
              </div> */}
            <S.StyledImage
              src={parseContentfulFileUrl(contentfulServicesPage.aiServices.fixed.srcWebp)}
              preview={parseContentfulFileUrl(
                contentfulServicesPage.aiServices.previewFixed.srcWebp,
              )}
            />
          </Reveal>
          <Visible md sm xs>
            <Spacer h40 />
          </Visible>
        </Col>
        <Col lg={6} md={12}>
          <Fade direction="right" triggerOnce>
            <span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Headline1>AI Services</Headline1>
                <Spacer w16 />
                <Pill.New label="New!" />
              </div>
              <Spacer h08 />
              <Body1>
                We help you identify the right AI solution for your business and build it.
              </Body1>
              <Spacer h24 />
              <ul>
                <li>
                  <Body1>Custom knowledge chatbots</Body1>
                </li>
                <li>
                  <Body1>ChatGPT integrations</Body1>
                </li>
                <li>
                  <Body1>Image generation</Body1>
                </li>
                <li>
                  <Body1>Audio transcriptions</Body1>
                </li>
                <li>
                  <Body1>Task automation</Body1>
                </li>
                <li>
                  <Body1>and more</Body1>
                </li>
              </ul>
              <Spacer h40 />
              <Button
                title="See our success stories"
                onClick={() => navigateToWorkWithCategoryFilters('ai')}
              />
            </span>
          </Fade>
        </Col>
      </Row>
      <Spacer size="large" />
      <Row style={{ alignItems: 'center' }} gutterWidth={140}>
        <Col lg={6} md={8}>
          <Reveal triggerOnce keyframes={animations.zoomFade}>
            <S.StyledImage
              src={parseContentfulFileUrl(contentfulServicesPage.designAndBranding.fixed.srcWebp)}
              preview={parseContentfulFileUrl(
                contentfulServicesPage.designAndBranding.previewFixed.srcWebp,
              )}
            />
          </Reveal>
          <Visible md sm xs>
            <Spacer h40 />
          </Visible>
        </Col>
        <Col lg={6} md={12}>
          <Fade direction="right" triggerOnce>
            <span>
              <Headline1>Design and Branding</Headline1>
              <Spacer h08 />
              <Body1>
                We translate your business needs into apps, software and branded websites that
                deliver powerful functionality with beautiful UX/UI.
              </Body1>
              <Spacer h24 />
              <ul>
                <li>
                  <Body1>Sprint workshops</Body1>
                </li>
                <li>
                  <Body1>Interactive Prototypes</Body1>
                </li>
                <li>
                  <Body1>Wireframes</Body1>
                </li>
                <li>
                  <Body1>Mockups</Body1>
                </li>
                <li>
                  <Body1>Branding &amp; Identity</Body1>
                </li>
              </ul>
              <Spacer h40 />
              <Button
                title="See our success stories"
                onClick={() => navigateToWorkWithCategoryFilters(['ui_design'])}
              />
            </span>
          </Fade>
        </Col>
      </Row>
      <Spacer size="large" />
      <Row style={{ alignItems: 'center' }} gutterWidth={140}>
        <Visible md sm xs>
          <Col lg={6} md={8}>
            <Reveal triggerOnce keyframes={animations.zoomFade}>
              <S.StyledImage
                src={parseContentfulFileUrl(contentfulServicesPage.productDesign.fixed.srcWebp)}
                preview={parseContentfulFileUrl(
                  contentfulServicesPage.productDesign.previewFixed.srcWebp,
                )}
              />
            </Reveal>
            <Spacer h40 />
          </Col>
        </Visible>
        <Visible xxl xl lg>
          <Col lg={6} md={8}>
            <Reveal triggerOnce keyframes={animations.zoomFade}>
              <S.StyledImage
                src={parseContentfulFileUrl(contentfulServicesPage.productDesign.fixed.srcWebp)}
                preview={parseContentfulFileUrl(
                  contentfulServicesPage.productDesign.previewFixed.srcWebp,
                )}
              />
            </Reveal>
          </Col>
        </Visible>
        <Col lg={6}>
          <Fade direction="right" triggerOnce>
            <span>
              <Headline1>Product Design</Headline1>
              <Spacer h08 />
              <Body1>
                When building a web or a mobile application, it’s important that the business and
                product strategy is aligned with the user experience. We help you build a meaningful
                and useful digital product. Depending on your need we can help you
              </Body1>
              <Spacer h24 />
              <ul>
                <li>
                  <Body1>Define target audience</Body1>
                </li>
                <li>
                  <Body1>Perform a competition analysis and differentiation strategy</Body1>
                </li>
                <li>
                  <Body1>Setup monetization strategy</Body1>
                </li>
                <li>
                  <Body1>Set an MVP strategy</Body1>
                </li>
                <li>
                  <Body1>Tailor a Product Roadmap</Body1>
                </li>
                <li>
                  <Body1>Write a Technical Specification</Body1>
                </li>
                <li>
                  <Body1>Write user stories and define clear user flows</Body1>
                </li>
              </ul>
            </span>
            <Spacer h40 />
            <Button
              title="See our success stories"
              onClick={() => navigateToWorkWithCategoryFilters('product_design')}
            />
          </Fade>
        </Col>
      </Row>
      <Spacer size="large" />
      <Row style={{ alignItems: 'center' }} gutterWidth={140}>
        <Col lg={6} md={8}>
          <Reveal triggerOnce keyframes={animations.zoomFade}>
            <S.StyledImage
              src={parseContentfulFileUrl(contentfulServicesPage.softwareDevelopment.fixed.srcWebp)}
              preview={parseContentfulFileUrl(
                contentfulServicesPage.softwareDevelopment.previewFixed.srcWebp,
              )}
            />
          </Reveal>
          <Visible md sm xs>
            <Spacer h40 />
          </Visible>
        </Col>
        <Col lg={6}>
          <Fade direction="right" triggerOnce>
            <span>
              <Headline1>Software Development</Headline1>
              <Spacer h08 />
              <Body1>
                Transform your idea into a ready-to-use solution — no matter the size or scope of
                your project — by getting quick access to a cohesive team of front and backend
                developers.
              </Body1>
              <Spacer h24 />
              <ul>
                <li>
                  <Body1>Frontend Development</Body1>
                </li>
                <li>
                  <Body1>Backend Development</Body1>
                </li>
                <li>
                  <Body1>Web Applications</Body1>
                </li>
                <li>
                  <Body1>Mobile Applications</Body1>
                </li>
                <li>
                  <Body1>Product ownership</Body1>
                </li>
                <li>
                  <Body1>Interim CTO</Body1>
                </li>
              </ul>
            </span>
            <Spacer h40 />
            <Button
              title="See our success stories"
              onClick={() => navigateToWorkWithCategoryFilters('software_development')}
            />
          </Fade>
        </Col>
      </Row>
      <Spacer size="large" />
      <Row>
        <Col lg={6} offset={{ lg: 6 }}>
          <Fade direction="right" triggerOnce>
            <span>
              <Headline1>
                We’re a digital product studio
                <br />
                built for innovators.
              </Headline1>
              <Spacer h08 />
              <Body1>
                Redmind was founded in 2008 as an IT consulting company, ten years later we started
                our journey as a digital product studio.
                <br />
                We do not focus on job titles but on ownership and collaboration, everyone's idea is
                valid, whether it's a method to reduce churn on subscriptions or how to create an
                app-less solution for micro rentals, everyone has a voice. We believe incredible
                ideas require an incredible team, a collaborative environment, flexible processes
                and a common goal for success.
              </Body1>
            </span>
          </Fade>
        </Col>
      </Row>
    </Container>
  )
}

export default ServicesContent
