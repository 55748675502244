import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import { Col, Container, Row, Visible } from 'react-grid-system'
import { Spacer } from '../../theme/base'
import { Body1, Title2 } from '../../theme/typography'
import { parseContentfulFileUrl } from '../../util/helpers'
import * as S from './styled'

type Props = {}

const query = graphql`
  query {
    contentfulAsset(title: { eq: "Blob3" }) {
      fixed(height: 800) {
        srcWebp
      }
    }
  }
`

const ServicesHero: FC<Props> = () => {
  const { contentfulAsset } = useStaticQuery(query)

  return (
    <S.Container style={{ position: 'relative' }}>
      <Visible lg xl xxl>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '48vw',
            height: '100vh',
            overflow: 'hidden',
            maxWidth: '1000px',
          }}
        >
          <Spacer size="header" />
          <img
            alt="blob hero image"
            src={parseContentfulFileUrl(contentfulAsset.fixed.srcWebp)}
            style={{
              objectFit: 'cover',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </div>
      </Visible>
      <Container style={{ height: '100%' }}>
        <Row
          style={{
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Col lg={6} md={10} offset={{ lg: 6, md: 2 }}>
            <div>
              <Title2>
                Hej, we're
                <br />
                Redmind.
              </Title2>
              <Spacer h24 />
              <Body1>
                We are a digital product studio.
                <br />
                <br />
                We partner with ambitious people and companies to build digital products and
                experiences. We help businesses make big decisions about their future by making
                tangible versions of tomorrow.
                <br />
                <br />
                Our studio envisions, design, build and launch digital products that bring value to
                business and people.
              </Body1>
            </div>
          </Col>
        </Row>
      </Container>
    </S.Container>
  )
}

export default ServicesHero
